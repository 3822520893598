import { Controller } from 'stimulus'
import { handleSubmitForm } from 'shared/assets/javascript/contact_form_7'

export default class extends Controller {

  connect() {
    this.formTarget = this.element.querySelector('form')
    this.modal = document.querySelector('.js-newsletterModal')
    this.modalContent = this.modal.querySelector('.modal__content')
  }

  async submit(e) {
    e.preventDefault()
    let response = await handleSubmitForm(this.formTarget)
    console.log({response})
    if (response.status !== 'mail_sent') return
    this.modalContent.classList.add('-form-success')
    setTimeout(() => {
      this.modal.classList.remove('active')
      this.modalContent.classList.remove('-form-success')
      this.modalContent.querySelector('#email_address').value = ''
    }, 5000)
  }

}
