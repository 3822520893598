import { Controller } from 'stimulus'
import throttle from 'lodash/throttle'

export default class extends Controller {

  initialize() {
    this.hamburgerToggle = document.querySelectorAll('.js-hamburger-toggle')
    this.hamburgerToggle.forEach((el) => {
      el.addEventListener('click', this.toggleMobileMenu.bind(this))
    })
    window.addEventListener('scroll', throttle(this.pagescroll, 250))
    // nav links
    this.navLinks = document.querySelectorAll('.header__nav--mobile .btn')
    this.navLinks.forEach((el) => {
      el.addEventListener('click', this.toggleMobileMenu.bind(this))
    })

    window.addEventListener('load', this.handlePageLoad.bind(this));
  }

  handlePageLoad() {
    const hash = window.location.hash;
    if (hash) {
      this.smoothScrollToAnchor(hash);
    }
  }

  smoothScrollToAnchor(selector) {
    const targetElement = document.querySelector(selector);
    if (targetElement) {
      setTimeout(() => {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 200);
    }
  }


  toggleMobileMenu() {
    let mobileNav = document.querySelector('.js-mobile-nav')
    mobileNav.classList.toggle('is-expanded')
    this.hamburgerToggle.forEach((el) => {
      el.classList.toggle('-close-icon')
    })
  }

  pagescroll() {
    let body = document.body
    let header = document.querySelector('#header-main')
    let footer = document.querySelector('footer.site-footer')
    let viewport = {
        top: window.scrollY,
        bot: window.scrollY + window.innerHeight
    }

    // Page has started scrolling
    let isScrolling = (viewport.top > 20)
    body.classList.toggle('-page-scrolling', isScrolling)
    body.classList.toggle('-show-btt-btn', isScrolling)
    header.classList.toggle('-page-scrolling', isScrolling)

    // Page is at the bottom near the footer
    let nearBottom = (viewport.bot > footer.offsetTop)
    body.classList.toggle('-page-bottom', nearBottom)
  }

}
