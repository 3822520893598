import { Controller } from 'stimulus'
import Dropkick from 'dropkickjs'

export default class extends Controller {
  static targets = ['otherSites']

  initialize() {
    new Dropkick(this.otherSitesTarget, {
      mobile: true,
      change() {
        if (!!this.value) window.open(this.value, '_blank')
      }
    })
    let scrollBtn = document.querySelector('.js-scrollToTop')
    scrollBtn.addEventListener('click', this.scrollToTop)
    this.newsletterModal = document.querySelector('.js-newsletterModal')
    let closeBtn = this.newsletterModal.querySelector('.js-modal-close')
    closeBtn.addEventListener('click', this.closeNewsletterModal.bind(this))
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  openNewsletterModal(e) {
    e.preventDefault()
    this.newsletterModal.classList.add('active')
    document.body.classList.add('-is-open-modal')
  }

  closeNewsletterModal(e) {
    e.preventDefault()
    this.newsletterModal.classList.remove('active')
    document.body.classList.remove('-is-open-modal')
  }

  loadScripts() {
    if (window.scriptsLoaded) return
    window.scriptsLoaded = true
    this.fbRoot = document.getElementById('fb-root')
    if (!this.fbRoot) return
    if (!!FB && this.fbRoot.children.length == 0) {
      this.fbRoot.innerHTML = this.fbRootSaved
    }
    if (!!FB) return FB.XFBML.parse()
    OneTrustReady.register(this.loadScriptsOneTrust)
  }

  loadScriptsOneTrust() {
    OneTrust.InsertScript(
      'https://connect.facebook.net/en_US/sdk.js',
      'head',
      this.getFB,
      null,
      ONETRUST_SOCIAL_GROUP
    )
    OneTrust.InsertScript(
      'https://platform.twitter.com/widgets.js',
      'head', null, null, ONETRUST_SOCIAL_GROUP
    )
  }

  getFB() {
    FB.init({ appId: 1264050613694938, xfbml: true, version: 'v2.10' })
    this.fbRootSaved = this.fbRoot.innerHTML
  }

}
